<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Purchase report
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                </div>
              </div>

              <v-card-text>
                <v-form ref="frm">
                  <v-card-text class="px-0 py-0">
                    <!-- <v-row>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          >Season</label
                        >

                        <v-select
                          :items="seasons"
                          item-value="id"
                          item-text="name"
                          v-model="season"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            select-style
                            mt-5
                          "
                          outlined
                          chips
                          height="46"
                          placeholder="Select Season"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >From (Start date)</label
                        >

                        <v-menu
                          ref="start_date_menu"
                          v-model="start_date_menu"
                          :close-on-content-click="false"
                          :return-value.sync="start_date_menu"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.start_date"
                              v-bind="attrs"
                              v-on="on"
                              hide-details="auto"
                              class="
                                input-style
                                font-size-input
                                text-light-input
                                placeholder-light
                                input-icon
                                mt-5
                              "
                              dense
                              flat
                              filled
                              solo
                              height="46"
                              placeholder="Start Date"
                              persistent-hint
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="green lighten-1"
                            header-color="primary"
                            v-model="editedItem.start_date"
                            :max="editedItem.end_date"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="start_date_menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.start_date_menu.save(
                                  editedItem.start_date
                                )
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span>To
                          (End date)</label
                        >

                        <v-menu
                          ref="end_date_menu"
                          v-model="end_date_menu"
                          :close-on-content-click="false"
                          :return-value.sync="end_date_menu"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.end_date"
                              v-bind="attrs"
                              v-on="on"
                              hide-details="auto"
                              class="
                                input-style
                                font-size-input
                                text-light-input
                                placeholder-light
                                input-icon
                                mt-5
                              "
                              dense
                              flat
                              filled
                              solo
                              height="46"
                              placeholder="To Date"
                              persistent-hint
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="green lighten-1"
                            header-color="primary"
                            v-model="editedItem.end_date"
                            :min="editedItem.start_date"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="end_date_menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.end_date_menu.save(editedItem.end_date)
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row> -->

                    <v-row>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                            </strong></span>Auction center</label>

                        <v-autocomplete :items="auctionCenter" item-text="auction_center_name" item-value="id"
                          hide-details="auto" v-model="editedItem.auctionCenter" class="
                                input-style
                                font-size-input
                                text-light-input
                                placeholder-light
                                border-radius-md
                                select-style
                                mt-3
                                mb-0
                              " outlined height="46" single-line :rules="[(v) => !!v || 'Auction center is required']"
                          placeholder="Select auction center">
                          <template v-slot:selection="{ item }">
                            <v-text label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-caption ls-0">{{
                                item.auction_center_name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                            </strong></span>Season</label>

                        <v-autocomplete :items="seasons" item-value="id" hide-details="auto" v-model="editedItem.season"
                          class="
                                input-style
                                font-size-input
                                text-light-input
                                placeholder-light
                                border-radius-md
                                select-style
                                mt-3
                                mb-0
                              " return-object outlined height="46" @change="filterSeasonSale()" single-line
                          :rules="[(v) => !!v || 'Season is required']" placeholder="Select Season">
                          <template v-slot:selection="{ item }">
                            <v-text name="ss_name" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text name="ss_name" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                            </strong></span>Sale No</label>

                        <v-autocomplete :items="seasonSale" item-value="id" hide-details="auto"
                          v-model="editedItem.seasonSale" class="
                                input-style
                                font-size-input
                                text-light-input
                                placeholder-light
                                border-radius-md
                                select-style
                                mt-3
                                mb-0
                              " return-object outlined height="46" single-line
                          :rules="[(v) => !!v || 'Sale No is required']" placeholder="Select Sale No">
                          <template v-slot:selection="{ item }">
                            <v-text name="ss_name" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-caption ls-0">{{
                                item.season_sale
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text name="ss_name" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-caption ls-0">{{
                                item.season_sale
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions dense class="card-padding d-flex justify-end">
                    <v-btn @click="close" elevation="0" :ripple="false" height="43" class="
                            font-weight-bold
                            text-capitalize
                            btn-ls btn-secondary
                            bg-light
                            px-6
                          ">Clear</v-btn>

                    <v-btn @click="searchPurchase()" elevation="0" :ripple="false" height="43" dark class="
                            font-weight-bold
                            text-capitalize
                            btn-ls btn-primary
                            bg-success
                            px-6
                          ">Search</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card-text>
              <v-card-text class="px-0 py-0" v-if="items.length > 0">
                <v-data-table :headers="headers" :items="items" item-key="id" class="table" :footer-props="{
                  'items-per-page-options': [100, 200, 500, -1],
                }" mobile-breakpoint="0">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Purchase history</v-toolbar-title>

                      <v-spacer></v-spacer>
                      <v-btn @click="excelExport" elevation="0" :ripple="false" height="43" dark class="
                              font-weight-bold
                              text-capitalize
                              btn-ls btn-primary
                              bg-success
                              py-3
                              px-6
                            ">Export to excel
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.sale_date`]="{ item }">
                    <span>
                      {{ formatDate(item.sale_date) }}
                    </span>
                  </template>
                  <template v-slot:[`item.sl_no`]="{ item }">
                    <span>
                      {{ findSerialNo(item) }}
                    </span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { json2excel } from "js2excel";
import api from "../api";
import seasonApi from "../../../master/Season/api";
import apiMaster from "../../../master/master-get-api";
export default {
  name: "PurchaseReport",
  data() {
    return {
      auctionCenter: [],

      seasons: [],
      seasonSale: [],
      season: "",
      page: 1,
      pageCount: 0,
      overlay: false,
      buyingType: [
        { name: "AUCTION" },
        { name: "PRIVATE" },
        { name: "INTERNAL TRANSFER" },
      ],

      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      items: [],
      search: "",
      editedIndex: -1,
      start_date_menu: false,
      end_date_menu: false,
      editedItem: {
        buyingType: "",
        start_date: null,
        end_date: null,
        auctionCenter: null,
        season: null,
        seasonSale: null,
      },
      defaultItem: {
        buyingType: "",
        start_date: null,
        end_date: null,
        auctionCenter: null,
        season: null,
        seasonSale: null,
      },
      headers: [
        {
          text: "#",
          width: "80",
          value: "sl_no",
        },
        {
          text: "Material",
          width: "80",
          value: "item_name",
        },
        {
          text: "Batch no",
          width: "150",
          value: "batch_no",
        },
        {
          text: "Garden",
          width: "150",
          value: "mark_name",
        },
        {
          text: "Grade",
          width: "150",
          value: "grade_name",
        },

        {
          text: "Lot no",
          width: "150",
          value: "lot_no",
        },
        {
          text: "Invoice no",
          width: "150",
          value: "invoice_no",
        },
        {
          text: "Season",
          width: "150",
          value: "season_name",
        },
        {
          text: "Sale no",
          width: "150",
          value: "sale_no",
        },
        {
          text: "Sale Date",
          width: "150",
          value: "sale_date",
        },
        {
          text: "Total pack",
          width: "150",
          value: "no_of_packages",
        },
        {
          text: "Quantity",
          width: "150",
          value: "total_net_kgs",
        },
        {
          text: "Price",
          width: "150",
          value: "purchased_rate",
        },
        {
          text: "Amount",
          width: "150",
          value: "tea_value",
        },
      ],
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    async setDates() {
      let date = new Date();
      let d = new Date(date.getFullYear(), date.getMonth(), 1);
      let d1 = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.editedItem.start_date = this.formatDate(d);
      this.editedItem.end_date = this.formatDate(d1);
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },

    validate() {
      return this.$refs.frm.validate();
    },
    findSerialNo(item) {
      return this.items.indexOf(item) + 1;
    },
    excelExport() {
      let date_time = new Date();
      date_time = `${(date_time.getMonth() + 1).toString().padStart(2, "0")}/${date_time.getDate().toString().padStart(2, "0")}/${date_time
        .getFullYear()
        .toString()}`;
      let fileName = `Seasonwise Purchase-` + date_time.replaceAll(`/`, `-`);

      let data = this.items.map((item, i) => {
        return {
          "#": i + 1,
          Material: item.item_name,
          "Batch no": item.batch_no,
          Garden: item.mark_name,
          Grade: item.grade_name,
          "Lot no": item.lot_no,
          "Invoice no": item.invoice_no,
          Season: item.season_name,
          "Sale no": item.sale_no,
          "Sale Date": this.formatDate(item.sale_date),
          "Total pack": item.no_of_packages,
          Quantity: item.quantity,
          Price: item.purchased_rate,
          Amount: item.tea_value,
        };
      });

      try {
        json2excel({
          data,
          name: fileName,
          formateDate: "yyyy/mm/dd",
        });
      } catch (e) {
        console.error("Excel export error:", e);
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
        showConfirmButton: false,
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 3000,
        icon: "error",
        showConfirmButton: false,
      });
    },
    noDataAlert(message) {
      this.$swal({
        text: message,

        timer: 3000,

        showConfirmButton: false,
      });
    },

    async initialize() {
      try {
        this.auctionCenter = await apiMaster.getAuctionCenter();
        this.seasonSale = await apiMaster.getSeasonSale();
        this.seasons = await apiMaster.getSeason();
        this.setDates();
      } catch (err) {
        this.showErrorAlert(err);
      }
    },

    async filterSeasonSale() {
      this.overlay = true;
      this.editedItem.seasonSale = 0;
      this.seasonSale = await apiMaster.getSeasonSaleBySeasonAndAuctionCenter(
        this.editedItem.season.id,
        this.editedItem.auctionCenter
      );
      this.overlay = false;
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    closeDialogue() {
      this.dialog = false;
      this.$refs.frmSave.reset();
    },
    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.reset();
      });
    },
    reset() {
      this.$refs.frm.reset();
    },

    searchValidation() {
      return this.$refs.frmFilter.validate();
    },
    saveValidation() {
      return this.$refs.frmSave.validate();
    },

    async searchPurchase() {
      if (this.validate()) {
        this.items = await api.getReportBySales(this.editedItem.seasonSale.id);
        if (this.items.length == 0) {
          this.noDataAlert("No Purchase Data Found");
        }
      }
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Allocation" : "Edit Allocation";
    },
    selectAll() {
      return this.item_name.length === this.item_names.length;
    },
    selectSome() {
      return this.item_name.length > 0 && !this.selectAll;
    },
    icon() {
      if (this.selectAll) return "ni-fat-remove text-lg";
      if (this.selectSome) return "ni-fat-delete";
      return "fa-square-o";
    },
  },
};
</script>
